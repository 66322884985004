import React from "react";
const Logo = ({ fill }) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="158.2394"
    height="28.531218"
  >
    <g id="layer1" fill={fill}>
      <path d="m 9.239642,22.645663 q -1.8190084,0 -3.4947877,-0.716145 Q 4.0833979,21.213373 2.7943369,19.86702 1.5195988,18.520668 0.76048503,16.587076 0.00137137,14.639162 0.00137137,12.204269 q 0,-2.4348927 0.74479076,-4.4114532 Q 1.490953,5.8019327 2.8086598,4.3696427 4.1120437,2.9516756 5.8880833,2.178239 7.664123,1.4048023 9.6836521,1.4048023 q 1.5039039,0 2.7213509,0.3723955 1.217446,0.3723954 2.32031,1.0455717 l 0.587239,-0.9739572 h 1.145832 l 0.128906,7.247387 H 15.412812 Q 15.183645,8.0363051 14.725313,6.8045357 14.26698,5.5584434 13.651095,4.6990694 12.963596,3.7251122 12.018285,3.180842 11.072973,2.6222489 9.8555271,2.6222489 q -1.3893217,0 -2.5924454,0.6158847 Q 6.074281,3.8540183 5.2005841,5.0571419 4.3698559,6.2172968 3.8972002,8.0076593 3.4245445,9.7980223 3.4245445,12.003748 q 0,1.947915 0.4869786,3.580725 0.5013015,1.618488 1.4179671,2.850257 0.9166656,1.217447 2.1627579,1.904946 1.2604153,0.687499 2.8072879,0.687499 1.160155,0 2.134113,-0.358072 0.973957,-0.372396 1.690102,-0.973957 0.716145,-0.630208 1.260415,-1.43229 0.54427,-0.802083 0.88802,-1.747394 l 1.074217,0.515624 q -1.231769,3.022132 -3.265621,4.325516 -2.033852,1.289061 -4.84114,1.289061 z" />
      <path d="m 32.514355,14.968589 q 0,1.489581 -0.472656,2.936194 -0.472656,1.43229 -1.346353,2.434893 -0.98828,1.145832 -2.291664,1.704425 -1.303384,0.558593 -2.921871,0.558593 -1.346353,0 -2.563799,-0.501301 -1.217447,-0.515625 -2.162758,-1.489582 -0.930989,-0.959634 -1.489582,-2.334633 -0.54427,-1.389321 -0.54427,-3.108069 0,-3.30859 1.919268,-5.4140557 1.933592,-2.1054665 5.055984,-2.1054665 2.964841,0 4.884109,2.0052065 1.933592,1.9908827 1.933592,5.3137957 z m -2.964841,0.02865 q 0,-1.074217 -0.214843,-2.263018 -0.214844,-1.203124 -0.644531,-2.033852 -0.458332,-0.8880197 -1.203123,-1.4179667 -0.744791,-0.54427 -1.819009,-0.54427 -1.102863,0 -1.890622,0.54427 -0.773437,0.54427 -1.260416,1.4895817 -0.458332,0.873697 -0.64453,1.990883 -0.186198,1.117186 -0.186198,2.162758 0,1.374998 0.214844,2.549476 0.229166,1.160155 0.716145,2.076821 0.486978,0.916665 1.217446,1.446613 0.744791,0.515624 1.833331,0.515624 1.790363,0 2.835935,-1.733071 1.045571,-1.733071 1.045571,-4.783849 z" />
      <path d="m 47.109389,18.850095 q -0.88802,1.690102 -2.377601,2.692705 -1.489582,1.002603 -3.423173,1.002603 -1.704426,0 -2.993487,-0.601562 -1.274738,-0.601562 -2.105466,-1.632811 -0.830728,-1.031248 -1.246092,-2.406247 -0.401041,-1.374998 -0.401041,-2.907549 0,-1.374998 0.429687,-2.692705 0.444009,-1.33203 1.289061,-2.3919237 0.816405,-1.016926 2.019529,-1.6328108 1.217446,-0.6302077 2.721351,-0.6302077 1.546873,0 2.649736,0.4869786 1.102863,0.4869787 1.790363,1.3320299 0.658853,0.8020817 0.973957,1.8906227 0.329427,1.08854 0.329427,2.363278 v 0.945312 h -9.352854 q 0,1.389321 0.257812,2.52083 0.272135,1.131509 0.845051,1.990883 0.558593,0.830728 1.460936,1.317707 0.902343,0.472656 2.148435,0.472656 1.274738,0 2.191404,-0.587239 0.930988,-0.601562 1.790362,-2.148435 z m -3.165361,-5.385411 q 0,-0.802082 -0.143229,-1.718748 -0.128906,-0.916666 -0.429687,-1.53255 -0.329427,-0.6588537 -0.902343,-1.0742177 -0.572916,-0.4153639 -1.475258,-0.4153639 -1.503905,0 -2.506508,1.2604149 -0.98828,1.2460927 -1.074217,3.4804647 z" />
      <path d="m 63.881506,21.915195 -4.597651,0.315104 -0.214843,-0.200521 v -1.790362 h -0.08594 q -0.343749,0.358072 -0.773436,0.773436 -0.429687,0.401042 -0.98828,0.730468 -0.644531,0.386719 -1.246093,0.587239 -0.601561,0.200521 -1.704425,0.200521 -1.8763,0 -2.86458,-1.217447 -0.973957,-1.231769 -0.973957,-3.566402 v -6.760409 q 0,-0.515624 -0.200521,-0.859374 -0.186197,-0.3580717 -0.558593,-0.6158837 -0.257812,-0.171875 -0.64453,-0.24349 -0.386718,-0.08594 -0.816406,-0.114583 V 8.2225029 l 4.712235,-0.3437497 0.214843,0.2005206 V 17.89046 q 0,1.532551 0.687499,2.305987 0.6875,0.773437 1.647134,0.773437 0.787759,0 1.403644,-0.24349 0.615885,-0.243489 1.031249,-0.572916 0.386718,-0.30078 0.673176,-0.630207 0.300781,-0.34375 0.458333,-0.630208 v -8.006501 q 0,-0.472656 -0.200521,-0.830728 -0.20052,-0.3723957 -0.558593,-0.6302077 -0.257812,-0.171875 -0.773436,-0.20052 -0.501302,-0.04297 -1.131509,-0.07161 V 8.2225029 l 5.127598,-0.3437497 0.214843,0.2005206 V 19.294104 q 0,0.486979 0.200521,0.830729 0.200521,0.343749 0.558593,0.587239 0.257812,0.157551 0.615885,0.214843 0.358072,0.04297 0.787759,0.05729 z" />
      <path d="m 76.428366,9.8696363 q 0,0.7304677 -0.358073,1.3033837 -0.358072,0.558593 -1.102863,0.558593 -0.802082,0 -1.231769,-0.415364 -0.415364,-0.415364 -0.415364,-0.916666 0,-0.315103 0.04297,-0.5729157 0.05729,-0.257812 0.100261,-0.515624 -0.673177,0 -1.575519,0.54427 -0.902343,0.5442697 -1.532551,1.5468727 v 8.221345 q 0,0.501302 0.186198,0.816405 0.200521,0.315104 0.615885,0.472656 0.358072,0.143229 0.916665,0.214844 0.572916,0.07161 0.98828,0.10026 v 0.945311 h -7.447908 v -0.945311 q 0.329427,-0.02865 0.673177,-0.05729 0.358072,-0.02864 0.615884,-0.114583 0.401042,-0.128906 0.587239,-0.44401 0.200521,-0.329426 0.200521,-0.845051 v -8.722646 q 0,-0.44401 -0.214844,-0.873697 -0.20052,-0.4296867 -0.572916,-0.6874987 -0.272135,-0.171875 -0.64453,-0.24349 -0.372396,-0.08594 -0.802083,-0.114583 V 8.193857 l 4.697912,-0.3151038 0.20052,0.2005206 v 1.9908832 h 0.07161 q 0.88802,-1.1601547 1.97656,-1.7617167 1.08854,-0.6015619 1.990883,-0.6015619 0.902343,0 1.460936,0.587239 0.572916,0.5872389 0.572916,1.5755189 z" />
      <path d="m 84.248671,22.173007 h -6.889315 v -0.945311 q 0.329426,-0.02865 0.701822,-0.05729 0.372395,-0.04297 0.615884,-0.114583 0.401042,-0.128906 0.601562,-0.44401 0.200521,-0.329426 0.200521,-0.845051 V 3.4100084 q 0,-0.5156244 -0.229167,-0.9596343 Q 79.035135,1.9920413 78.677062,1.7342291 78.41925,1.5623543 77.731751,1.4191252 77.044252,1.2758962 76.628888,1.2472504 V 0.33058485 l 5.313796,-0.3294267 0.20052,0.2148435 V 19.594885 q 0,0.501302 0.186198,0.830729 0.186198,0.315103 0.601562,0.486978 0.315104,0.143229 0.615884,0.214844 0.315104,0.07161 0.701823,0.10026 z" />
      <path d="m 89.762987,1.8774581 q 0,0.572916 -0.372396,2.2057266 -0.372395,1.6328106 -0.830728,4.2395785 H 87.686166 Q 87.299448,5.8878701 86.912729,4.1691221 86.540334,2.4360512 86.540334,1.8774581 q 0,-0.6588535 0.401041,-1.13150915 0.401041,-0.4726557 1.217447,-0.4726557 0.773436,0 1.188801,0.4726557 0.415364,0.47265565 0.415364,1.13150915 z" />
      <path d="m 101.66531,15.255047 q 0.58724,0.515624 0.90235,1.1888 0.3151,0.673177 0.3151,1.647134 0,1.962237 -1.47526,3.208329 -1.460928,1.246093 -3.795564,1.246093 -1.23177,0 -2.277342,-0.44401 -1.031248,-0.44401 -1.475258,-0.816405 l -0.286458,0.930988 H 92.49866 l -0.143229,-4.912755 h 0.98828 q 0.114583,0.558593 0.486979,1.303384 0.372395,0.744791 0.902343,1.346353 0.558593,0.630207 1.332029,1.059894 0.78776,0.429687 1.761717,0.429687 1.374998,0 2.119793,-0.615884 0.744788,-0.630208 0.744788,-1.790363 0,-0.601562 -0.24349,-1.031249 -0.22917,-0.44401 -0.687498,-0.773436 -0.47266,-0.329427 -1.160156,-0.587239 -0.6875,-0.272135 -1.532551,-0.529947 -0.673176,-0.200521 -1.503904,-0.544271 -0.816405,-0.358072 -1.403644,-0.830728 -0.630208,-0.501301 -1.031249,-1.231769 -0.401041,-0.730468 -0.401041,-1.718748 0,-1.733071 1.289061,-2.9361947 1.289061,-1.2031235 3.451819,-1.2031235 0.830728,0 1.690102,0.2148435 0.873691,0.2148435 1.417961,0.5156245 l 0.32943,-0.7447909 h 1.03125 l 0.21484,4.5403591 h -0.98828 q -0.30078,-1.518227 -1.303378,-2.4635387 -0.988281,-0.9596341 -2.420571,-0.9596341 -1.174478,0 -1.847654,0.5872391 -0.673177,0.587239 -0.673177,1.4179667 0,0.64453 0.214844,1.074217 0.229166,0.429687 0.673176,0.730468 0.429687,0.286458 1.074218,0.544271 0.658853,0.243489 1.618487,0.558593 0.945307,0.300781 1.776035,0.701822 0.84505,0.401041 1.38932,0.88802 z" />
      <path d="m 129.552,16.186035 -0.38671,5.986972 h -16.87238 v -1.016926 q 0.42969,-0.02865 1.14583,-0.114583 0.71615,-0.10026 0.93099,-0.20052 0.45833,-0.214844 0.63021,-0.558593 0.18619,-0.34375 0.18619,-0.873697 V 4.7993297 q 0,-0.4726557 -0.15755,-0.8307282 Q 114.87103,3.5962061 114.36973,3.3670397 113.98301,3.180842 113.32416,3.037613 112.67963,2.894384 112.29291,2.8657382 V 1.8488123 h 15.72654 v 4.8984318 h -1.07421 q -0.28646,-1.0885404 -1.2461,-2.3059869 -0.94531,-1.2317694 -1.80468,-1.3463526 -0.44401,-0.057292 -1.0599,-0.085937 -0.61588,-0.028646 -1.40364,-0.028646 h -3.19401 v 8.0781154 h 2.29167 q 1.10286,0 1.60416,-0.200521 0.51563,-0.214843 0.90234,-0.701822 0.31511,-0.4153637 0.52995,-1.1028627 0.22917,-0.7018221 0.30078,-1.3177069 h 1.07422 v 7.9492096 h -1.07422 q -0.0573,-0.6875 -0.3151,-1.446613 -0.24349,-0.773437 -0.51563,-1.117187 -0.44401,-0.558593 -1.01692,-0.730467 -0.5586,-0.171875 -1.48958,-0.171875 h -2.29167 v 6.101555 q 0,0.916666 0.12891,1.446613 0.1289,0.529947 0.5013,0.802083 0.3724,0.272135 1.01693,0.358072 0.64453,0.07162 1.83333,0.07162 0.47265,0 1.24609,0 0.77344,0 1.30338,-0.05729 0.54427,-0.05729 1.11719,-0.200521 0.58724,-0.157552 0.83073,-0.415364 0.65885,-0.687499 1.375,-2.07682 0.73046,-1.403645 0.93098,-2.062498 z" />
      <path d="m 130.18222,26.784981 q 0,-0.716145 0.38672,-1.174478 0.40104,-0.444009 0.98828,-0.444009 0.45833,0 0.78776,0.171874 0.32942,0.171875 0.58723,0.44401 0.21485,0.214844 0.45834,0.587239 0.24349,0.372396 0.38672,0.615885 0.71614,-0.386718 1.70442,-2.019529 1.0026,-1.632811 1.44661,-2.86458 -1.36067,-3.394527 -2.46354,-6.07291 -1.10286,-2.678382 -2.30598,-5.542962 -0.27214,-0.6588537 -0.91667,-1.0169257 -0.64453,-0.372396 -1.28906,-0.472656 V 8.050628 h 6.44531 v 1.0026033 q -0.42969,0.01432 -1.03125,0.186197 -0.60156,0.157552 -0.60156,0.372396 0,0.08594 0.0573,0.286458 0.0716,0.2005197 0.15755,0.4153637 0.54427,1.417967 1.66146,4.096349 1.11718,2.66406 1.73307,4.268225 0.64453,-1.532551 1.33203,-3.165361 0.70182,-1.647134 1.56119,-3.881506 0.10026,-0.272135 0.28646,-0.744791 0.1862,-0.486979 0.1862,-0.830728 0,-0.3723957 -0.65886,-0.6874987 -0.64453,-0.329427 -1.23176,-0.401042 V 8.050628 h 5.24218 v 0.8880203 q -0.42969,0.07161 -1.13151,0.501301 -0.70182,0.415364 -1.04557,1.1888007 -1.70443,3.95312 -3.03646,7.204419 -1.31771,3.251298 -2.0052,4.783848 -0.90235,2.005206 -1.61849,3.122393 -0.70182,1.131509 -1.375,1.776039 -0.65885,0.601562 -1.20312,0.802083 -0.52995,0.214843 -1.10287,0.214843 -1.11718,0 -1.76171,-0.529947 -0.63021,-0.529948 -0.63021,-1.217447 z" />
      <path d="m 158.24077,18.850095 q -0.88802,1.690102 -2.37761,2.692705 -1.48958,1.002603 -3.42317,1.002603 -1.70442,0 -2.99349,-0.601562 -1.27473,-0.601562 -2.10546,-1.632811 -0.83073,-1.031248 -1.24609,-2.406247 -0.40105,-1.374998 -0.40105,-2.907549 0,-1.374998 0.42969,-2.692705 0.44401,-1.33203 1.28906,-2.3919237 0.81641,-1.016926 2.01953,-1.6328108 1.21745,-0.6302077 2.72135,-0.6302077 1.54688,0 2.64974,0.4869786 1.10286,0.4869787 1.79036,1.3320299 0.65886,0.8020817 0.97396,1.8906227 0.32943,1.08854 0.32943,2.363278 v 0.945312 h -9.35286 q 0,1.389321 0.25781,2.52083 0.27214,1.131509 0.84506,1.990883 0.55859,0.830728 1.46093,1.317707 0.90234,0.472656 2.14844,0.472656 1.27473,0 2.1914,-0.587239 0.93099,-0.601562 1.79036,-2.148435 z m -3.16537,-5.385411 q 0,-0.802082 -0.14322,-1.718748 -0.12891,-0.916666 -0.42969,-1.53255 -0.32943,-0.6588537 -0.90234,-1.0742177 -0.57292,-0.4153639 -1.47526,-0.4153639 -1.50391,0 -2.50651,1.2604149 -0.98828,1.2460927 -1.07422,3.4804647 z" />
    </g>
  </svg>
);
export default Logo;
